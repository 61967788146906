*, html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.nav-item>.active {
  border-bottom: 1px solid #333;
}

.textimage {
  margin-top: -40%;
}

.oleft {
  margin-top: -70% !important;
  margin-right: 60%;
}

.oright {
  margin-top: -70% !important;
}

.carousel .slide {
  min-width: 0% !important;
  margin: 0;
  position: relative;
  text-align: center;
  background: none !important;
}

.spacingtest {
  margin-top: 10%;
  margin-left: 2%;
}

.sizeimg {}

.testnav {
  margin-right: -15% !important;
}

.container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
  width: 100%;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-right: auto;
  /* margin-left: auto; */
}

.ocean {
  height: 55%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #015871;
}

.wave {
  background: url(https://cdn.kcak11.com/codepen_assets/wave_animation/wave.svg) repeat-x;
  position: absolute;
  top: -198px;
  width: 3500px;
  height: 198px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -175px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

.endWave {
  display: none;
}

.textimage1 {
  margin-top: -28%;
}

/* .m1{
  margin-left: -25%;
 }
 
 .m2{
  margin-right: -35%;
 } */

.background_homepage {
  background: url("../Images/Background_homepage.png") no-repeat;
  /* -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover; 
  background-size: cover; */
  height: auto;
  width: 100%;
  position: relative;
  background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

}

.container_foo {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Circle_New {
  border-radius: 51%;
  width: 100px;
  line-height: 100px;
  border: 2.6px solid Chartreuse;
  position: absolue;
  text-align: center;
  float: left;
}

.circle-content {
  float: left;
  line-height: 1;
  margin-top: -0.5em;
  padding-top: 50%;
  text-align: center;
  width: 100%;
  /* color: #3C3C3C;
 
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 50px;
  text-align: center;
 
  width: 50%; */
}

.footerback {
  background: url("../Images/Water.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 1000px;
  width: 100%;
}

.loaderclass {
  position: fixed;
  top: 45%;
  left: 45%;
  z-index: 9999;
}

.errorClass {
  color: #ca2b2b;
  padding-left: 28px;
}

.background_journey {
  background: url("../Images/Journey1.png") no-repeat center;
}

.textcol {
  background-color: lightcyan !important;
}

.nav-link>.active {
  border-bottom: 0px solid #1F61BE !important;
}

.menu_text {
  color: #374667 !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 25px !important;
  text-align: right !important;
}

.smart-hydration-for {
  color: #1F61BE;
  font-family: Poppins;
  font-size: 66px;
  font-weight: 600;
  letter-spacing: -0.8px;
  line-height: 70px;
  text-align: center;
  
}

.smart-hydration-for-Product {
  color: #1F61BE;
  font-family: Poppins;
  font-size: 66px !important;
  font-weight: 600 !important;
  letter-spacing: -0.8px;
  line-height: 81px !important;
  text-align: center;
  padding-top: 5% !important;
}

.smart-hydration-for-Product_PP {
  color: #1F61BE;
  font-family: Poppins;
  font-size: 66px !important;
  font-weight: 600 !important;
  letter-spacing: -0.8px;
  line-height: 81px !important;
  text-align: center;
  padding-top: 15% !important;
}

.circle-responsive {
  height: 0;
  padding-bottom: 100%;
  width: 100%;
  background-color: white;
  box-shadow: 10px 40px 60px 0 rgba(0, 0, 0, 0.05);
  border-radius: 50%;
}

.in-depth-innovative {
  color: #374667;
  font-family: Poppins;
  font-size: 31px;
  font-weight: 500;
  letter-spacing: -0.38px;
  text-align: center;
  padding-top: 25px;
}

.in-depth-innovative-Product {
  color: #374667;
  font-family: Poppins;
  font-size: 31px;
  font-weight: 500;
  letter-spacing: -0.38px;
  line-height: 47px;
  text-align: center;
}

.for-an-immersive-dri_ui {
  color: #676767;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.24px;
  line-height: 30px;
}

.convenient {
  color: #1F61BE;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.29px;
  line-height: 35px;
}

.arrowdiv {
  padding-top: 200px;
}

.explore {
  color: #374667;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 4.17px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  padding-top: 14px;
}



.engtext {
  color: #1F61BE;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
}

.rectangle_cause {
  height: auto;
  width: auto;
  background: linear-gradient(180deg, #3C3C3C 0%, #292929 100%);
  margin-top: -25%;
}

.Causecontent {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  padding-top: 30%;
  margin-left: 22%;
}

.Causecontent-p {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.quotetest {
  height: 150px;
  margin-top: 15%;
  margin-left: -10%;
}

.stay-connected {
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 85px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 100px;
}

.line-2 {
  box-sizing: border-box;
  height: 1px;
  width: 60px;
  border: 1px solid #FFFFFF;
  opacity: 0.5;
  background-color: #FFFFFF;
  /* box-sizing: border-box;
  
  width: 90px;
  border: 1px solid #FFFFFF;
  opacity: 0.5;
  margin-top: 0.5%; */
}

.line-2-product {
  box-sizing: border-box;
  height: 1px;
  width: 60px;
  border: 1px solid #3C3C3C;
  opacity: 0.5;
  margin-top: 0.5%;
  background-color: #3C3C3C;
}

.our-cause {
  opacity: 0.5;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  padding-left: 75px;
}

.our-water-connects-u {
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 49px;
  font-weight: 600;
  letter-spacing: -0.59px;
  line-height: 73px;
}

.ut-enim-ad-minim-ven {
  opacity: 0.6;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 35px;
  font-weight: 400;
}

.one {
  width: 70%;
}

.mobile1 {
  width: 200px;
  margin-top: -10%;
}

.mobile3 {
  width: 200px;
  margin-top: -10%;
}

.approacdiv {
  margin-top: -5%;
  height: auto;
  width: 100%;
}

.line-2-copy-2 {
  box-sizing: border-box;
  border: 1px solid #3C3C3C;
  opacity: 0.5;
  margin-top: 0.6%;
  background-color: #3C3C3C;
  box-sizing: border-box;
  height: 1px;
  width: 60px;
  margin-left: 10% !important;
}

.our-approach {
  opacity: 0.5;
  color: #3C3C3C;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
}

.successtext {
  color: #007bff;
}
.success_text_{
  width: 60%;
    text-align: left;
}

.oval {
  height: 688px;
  width: 688px;
  background-color: white;
  box-shadow: 10px 40px 60px 0 rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  margin: 0 auto;
}

.as-they-say {
  color: #1F61BE;
  margin-left: -10%;
  font-size: 80px;
  padding-left: 7%;
  margin-top: -20%;
  font-family: Playfair Display !important;
  font-weight: bold;
  letter-spacing: 0.8px;
}

.water-is-lifes-matt {
  color: #3C3C3C;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 50px;
  width: 60%;
  margin-left: 25%;
  margin-top: -30%;
  padding-left: 33px;
}

.jmar {
  margin-left: 6% !important;
}

.jmar1 {
  margin-left: 18% !important;
}

.albert-szent-gyorg {
  opacity: 0.54;
  color: #3C3C3C;
  font-family: Poppins;
  font-size: 22px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 36px;
  vertical-align: center;
  width: 50%;
  margin-left: 29%;
}

.oapproach {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.testim {
  margin-top: 10%;
  height: 600px !important;
}

.making-wave-making1 {
  position: absolute;
  color: white;
  font-family: Poppins;
  font-size: 49px;
  font-weight: 600;
  letter-spacing: -0.59px;
  line-height: 73px;
  top: 36%;
  padding-left: 140px;
}

.ut-enim-ad-minim-ven1011_1 {
  position: absolute;
  opacity: 0.8;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 35px;
  top: 58%;
  padding-left: 140px;
  width: 900px;
}

.ctest {
  color: red;
  margin-top: 10%;
  margin-left: 20%;
  font-size: 30px;
}

.mask {
  height: 700px;
  width: 100%;
  background: linear-gradient(180deg, #1F61BE 0%, #0B4392 100%);
}

.health-concern {
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 49px;
  font-weight: 600;
  letter-spacing: -0.59px;
  line-height: 73px;
}

.ut-enim-ad-minim-ven12 {
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 35px;
}

.g {
  margin-left: 20%;
  background-color: coral;
  margin-top: 10%;
  /* width: 600px !important;
  height: 800px !important; */
}

.waveim {
  width: 1920px;
  margin-top: 10%;
  height: 200px;
}

.rectangle_firstpage {
  height: 172px;
  width: auto;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
}

.rectangle_discover {
  margin-top: 15%;
  height: 580px;
  transform: scaleY(1);
  background: linear-gradient(0deg, #01030B 0%, #3C3C3C 100%);
}

.rectangle_Wave {
  height: 700px;
  width: auto;
  background: linear-gradient(180deg, #01030B 0%, #3C3C3C 100%);
}

.overimage {
  position: relative;
}

.making-wave-making {
  color: white;
  font-family: Poppins;
  font-size: 49px;
  font-weight: 600;
  letter-spacing: -0.59px;
  line-height: 73px;
  padding-top: 10% !important;
  margin-left: 15%;
  position: absolute;
}

.ut-enim-ad-minim-ven1011 {
  opacity: 0.8;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 35px;
  margin-left: 15%;
  width: 900px;
}

.wateri {
  width: 1920px;
  margin-top: -1%;
}

.oi {
  position: relative;
}

.ibox {
  box-sizing: border-box;
  height: 51px;
  width: 374px;
  border: 1px solid #FFFFFF;
  border-radius: 25.5px;
  background-color: rgba(255, 255, 255, 0.85);
  outline: none;
}

.ibox1 {
  box-sizing: border-box;
  height: 51px;
  width: 374px;
  border: 1px solid #FFFFFF;
  border-radius: 25.5px;
  background-color: rgba(255, 255, 255, 0.85);
  position: absolute;
  top: 82%;
  left: 30%;
  outline: none;
}

.ibox2 {
  color: red;
  position: absolute;
  top: 79.5%;
  left: 40%;
}

.ibtn {
  box-sizing: border-box;
  height: 51px;
  width: 174px;
  border-radius: 25.5px;
  background-color: #1F61BE;
  position: absolute;
  margin-top: 10%;
  color: white;
  border: none !important;
  cursor: pointer !important;
  outline: none !important;
}

.ibtn1 {
  box-sizing: border-box;
  height: 51px;
  width: 174px;
  border-radius: 25.5px;
  background-color: #1F61BE;
  position: absolute;
  top: 82%;
  left: 55%;
  color: white;
  border: none !important;
  cursor: pointer !important;
  outline: none !important;
}

.x-btn:focus, .button:focus, [type="submit"]:focus {
  outline: none !important;
}

.hrwi {
  width: 20px;
}

.privacy-policy {
  color: #707070;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}

.mel {
  padding-left: 60%;
}

.gd {
  position: relative;
}

.pl {
  position: absolute;
  left: 45%;
  top: 140%;
}

.footer-Product {
  height: auto;
  width: auto;
  background: linear-gradient(180deg, #3C3C3C 0%, #292929 100%);
}


.for-a-project-as-a {
  opacity: 0.83;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  padding-top: 68px;
}

.rectangle-copy-9-Product {
  box-sizing: border-box;
  height: 51px;
  width: 374px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 25.5px;
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
  outline: none;
  padding-left: 28px;
  margin-top: 18.5px;
}

.rectangle-copy-9-Product-textarea {
  box-sizing: border-box;
  height: 70px;
  width: 374px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 25.5px;
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
  outline: none;
  padding-top: 2%;
  padding-left: 28px;
  margin-top: 18.5px;
}

.ibtn-Product {
  box-sizing: border-box;
  /* height: 51px;
  width: 174px; */
  padding: 15.5px 69px;
  border-radius: 25.5px;
  background-color: #1F61BE;
  color: white;
  border: none !important;
  cursor: pointer !important;
  outline: none !important;
  font-weight: 600;
}

.onlypad {
  padding-top: 10%;
}

.privacy-policy-product {
  color: #B4B4B4;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}

.hrcolor {
  background-color: white;
  color: white;
  opacity: 0.1;
}

.our-cause-product {
  opacity: 0.5;
  color: #3C3C3C;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  width: 200px;
}

.ut-enim-ad-minim-ven-p {
  color: #374667;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}

.introducing-our-mobi {
  color: #1F61BE;
  font-family: Poppins;
  font-size: 49px;
  font-weight: 600;
  letter-spacing: -0.59px;
  line-height: 65px;
}

.lifepaced {
  color: #1F61BE;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.59px;
}

.flex-container-mobile {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex-container-mobile12 {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.flex-container-feature {
  display: flex;
  justify-content: space-around;
}

.sc {
  margin-top: -7%;
}

.mobile1 {
  width: 200px;
}

.mobile3 {
  width: 200px;
}

.line-2-copy-4-F {
  box-sizing: border-box;
  height: 1px;
  width: 60px;
  border: 1px solid #3C3C3C;
  opacity: 0.5;
  background-color: #3C3C3C;
}

.fe {
  margin-top: 10%;
}

.one-f {
  height: 20px;
  opacity: 0.5;
  color: #3C3C3C;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
}

.tab {
  width: 100%;
  padding: 25px;
  font-family: sans-serif;
  color: #444;
}

ul.inline {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 0px;
}

ul.inline li {
  display: inline-block;
  margin-left: 0;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 50px;
  border-bottom: 3px solid #eee;
  transition: all 0.5s;
  cursor: pointer;
  color: #1F61BE;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}

ul.inline li.selected {
  border-bottom: 5px solid #337ab7;
  color: #1F61BE;
}

.producty-features-f {
  height: 20px;
  opacity: 0.5;
  color: #3C3C3C;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
}

.just-because-youve-f {
  color: #374667;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 45px;
  width: 70%;
}

.ut-enim-ad-minim-ven-f {
  color: #374667;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  width: 75%;
}

.filtered-water {
  color: #1F61BE;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}

.ut-enim-ad-minim-ven-df {
  color: #374667;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
}

.hrc {
  border: 1px solid #979797;
}

.rectangle-process {
  height: auto;
  background: linear-gradient(180deg, #3C3C3C 0%, #292929 100%);
}

.line-2-copy-2-process {
  box-sizing: border-box;
  height: 1px;
  width: 60px;
  border: 1px solid #FFFFFF;
  opacity: 0.5;
  background-color: #FFFFFF;
}

.process {
  opacity: 0.5;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
}

.experience-elixr {
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 49px;
  font-weight: 600;
  letter-spacing: -0.59px;
  line-height: 65px;
}

.see-the-process-ho {
  opacity: 0.9;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.29px;
  line-height: 35px;
}

.oval-pr {
  box-sizing: border-box;
  height: 39px;
  width: 39px;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  text-align: center;
  color: white;
}

.step-1-will-go-here {
  opacity: 0.9;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.29px;
  line-height: 35px;
}

.line-2-dot {
  height: 205px;
  width: 2px;
  background-color: white;
  border-style: dotted;
}

.ut-enim-ad-minim-ven-ppp {
  opacity: 0.59;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 31px;
}

.errorcolor {
  color: red;
  position: absolute;
  top: 82%;
  left: 50%;
}

.rectangle_graident {
  height: 100px;
  width: auto;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  /* background-color: red; */
  /* padding-top: -10% !important; */
  margin-top: -1%;
}

.navbgcolor {
  background-color: red !important;
}

@media (max-width: 1366px) {
  .textimage {
    margin-top: -50% !important;
  }
  .textimage1 {
    margin-top: -40% !important;
  }
 
  .sc {
    margin-top: 0%;
  }
  .mobile1 {
    width: 200px;
  }
  .mobile3 {
    width: 200px;
  }
 
 
  .one {
    width: 80%;
  }
  .Causecontent {
    margin-left: 12%;
  }
  .ibox2 {
    color: red;
    position: absolute;
    top: 82%;
    left: 40%;
  }
  .ibox1 {
    top: 85%;
    left: 30%;
    outline: none;
  }
  .ibtn1 {
    top: 85%;
    left: 60%;
    border: none;
    cursor: pointer;
  }
}

@media (max-width: 1800px) {
  .textimage {
    margin-top: -40%;
  }
  .textimage1 {
    margin-top: -30%;
  }
}

@media (max-width: 1024px) {
  .textimage {
    margin-top: -55% !important;
  }
  .textimage1 {
    margin-top: -52% !important;
    margin-left: -0.2% !important;
  }
  ul.inline li {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 50px;
  }
  .errorcolor {
    color: red;
    position: absolute;
    top: 88%;
    left: 50%;
  }
 
  .one {
    width: 94%;
  }
  .Causecontent {
    margin-left: 6%;
  }
  .onlypad {
    padding-top: 15%;
  }
  .ibox2 {
    color: red;
    position: absolute;
    top: 85%;
    left: 40%;
  }
  .ibox1 {
    top: 90%;
    left: 20%;
    outline: none;
  }
  .ibtn1 {
    top: 90%;
    left: 60%;
    border: none;
    cursor: pointer;
  }
}

@media (max-width: 1024px) {
  .errorcolor {
    color: red;
    position: absolute;
    top: 88%;
    left: 50%;
  }
 
  .one {
    width: 94%;
  }
  .Causecontent {
    margin-left: 6%;
  }
  .onlypad {
    padding-top: 15%;
  }
}

@media (max-width: 812px) {
  .errorcolor {
    color: red;
    position: absolute;
    top: 93%;
    left: 44%;
  }
  .pclass {
    margin-top: 80%;
  }
  .onlypad {
    padding-top: 20%;
  }
  .stay-connected {
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 55px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 64px;
  }
 
 
  .in-depth-innovative-Product {
    padding-top: 0%;
  }
  .one {
    width: 75%;
  }
  .Causecontent {
    align-items: center;
    margin-left: 0%;
  }
  .ut-enim-ad-minim-ven {
    margin-left: 0%;
    margin-top: 35%;
  }
  .mask {
    height: 800px;
  }
  .mel {
    padding-left: 20%;
  }
}

@media (max-width: 768px) {
  .our-water-connects-u {
    font-size: 39px;
    font-weight: 600;
    line-height: 53px;
  }
  .flex-container-mobile {
    display: flex;
    justify-content: space-center;
    flex-wrap: wrap;
  }
  /* .textimage{
  margin-top: -55% !important;
  
  
  }
  
  .textimage1{
  margin-top: -70% !important;
 
  margin-left: 6.1% !important;
  
  
  } */
  .ibox2 {
    color: red;
    position: absolute;
    top: 68%;
    left: 30%;
  }
  .ibox1 {
    top: 75%;
    left: 25%;
    outline: none;
  }
  .ibtn1 {
    top: 88%;
    left: 38%;
    border: none;
    cursor: pointer;
  }
  ul.inline li {
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 50px;
  }
  .errorcolor {
    color: red;
    position: absolute;
    top: 93%;
    left: 44%;
  }
  .pclass {
    margin-top: 80%;
  }
  .onlypad {
    padding-top: 20%;
  }

 
 
  .one {
    width: 75%;
  }
  .Causecontent {
    align-items: center;
    margin-left: 0%;
  }
  .ut-enim-ad-minim-ven {
    margin-left: 0%;
    margin-top: 35%;
  }
  .mask {
    height: 800px;
  }
  .mel {
    padding-left: 20%;
  }
  .get_in_touch_txt {
    padding-left: 15px;
    padding-top: 40px;
  }
  .stay-connected-outer {
    /* padding-left: 15px; */
    position: relative !important;
  }
  .stay-connected {
    font-size: 33px;
    line-height: 0px;
  }
  
}

@media (max-width: 375px) {
  .textimage {
    margin-top: 0% !important;
    justify-content: center !important;
  }
  .rectangle-copy-9-Product {
    box-sizing: border-box;
    height: 51px;
    width: 294px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 25.5px;
    background-color: rgba(255, 255, 255, 0.15);
    color: white;
    outline: none;
  }
  .textimage1 {
    margin-top: 15% !important;
    margin-left: 0% !important;
    justify-content: center !important;
  }
  .ibox2 {
    color: red;
    position: absolute;
    top: 50%!important;
    left: 10%!important;
  }
  .ibox1 {
    top: 60%!important;
    left: 20%!important;
    outline: none;
    height: 30px !important;
    width: 250px !important;
  }
  .ibtn1 {
    top: 80%!important;
    left: 30%!important;
    border: none;
    cursor: pointer;
    height: 30px !important;
    width: 150px !important;
  }
  .errorcolor {
    color: red;
    position: absolute;
    top: 93%!important;
    left: 41%!important;
    font-size: 14px;
  }
  .smart-hydration-for-Product {
    margin-top: 15%;
  }
  .mask {
    height: 800px;
    width: 100%;
    background: linear-gradient(180deg, #1F61BE 0%, #0B4392 100%);
  }
  .smart-hydration-for-Product {
    color: #1F61BE;
    font-family: Poppins;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -0.8px;
    line-height: 51px;
    text-align: center;
    padding-top: 20%;
  }
  .pclass {
    margin-top: 0%;
  }
}

@media (max-width: 416px) {
  .making-wave-making1 {
    position: absolute;
    color: white;
    font-family: Poppins;
    font-size: 49px;
    font-weight: 600;
    letter-spacing: -0.59px;
    line-height: 73px;
    padding-left: 140px;
  }
  .ut-enim-ad-minim-ven1011_1 {
    position: absolute;
    opacity: 0.8;
    color: #FFFFFF;
    font-family: Poppins;
    letter-spacing: 0;
    line-height: 35px;
    top: 70%;
    padding-left: 140px;
    width: 300px !important;
  }
  .rectangle-copy-9-Product-textarea {
    box-sizing: border-box;
    height: 151px;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 25.5px;
    background-color: rgba(255, 255, 255, 0.15);
    color: white;
    outline: none;
    padding-top: 2%;
    padding-left: 28px;
  }
  .rectangle-copy-9-Product {
    box-sizing: border-box;
    height: 51px;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 25.5px;
    background-color: rgba(255, 255, 255, 0.15);
    color: white;
    outline: none;
    padding-top: 2%;
    padding-left: 28px;
  }
  .ut-enim-ad-minim-ven1011_1 {
    width: auto;
    margin-top: -45% !important;
  }
  .testim {
    height: 1000px !important;
  }
  .making-wave-making1 {
    font-size: 35px;
    line-height: 53px;
  }
  
  .smart-hydration-for-Product_PP {
    color: #1F61BE;
    font-family: Poppins;
    font-size: 56px !important;
    font-weight: 600 !important;
    letter-spacing: -0.8px;
    line-height: 81px !important;
    text-align: center;
    padding-top: 45% !important;
  }
  .introducing-our-mobi {
    color: #1F61BE;
    font-family: Poppins;
    font-size: 49px;
    font-weight: 600;
    letter-spacing: -0.59px;
    line-height: 65px;
    margin-left: 5%;
  }
  .oleft {
    margin-top: 0% !important;
    margin-right: 0%;
  }
  .oright {
    margin-top: 20% !important;
  }
  .g {
    margin-left: -7%;
    background-color: coral;
    margin-top: 10%;
  }
  .downtest {
    margin-left: 3% !important;
    margin-top: 0% !important;
  }
  .spacingtest {
    margin-top: 20% !important;
    margin-left: 2%;
  }
  .rectangle_discover {
    margin-top: 35%;
    height: 800px;
    transform: scaleY(1);
    background: linear-gradient(0deg, #01030B 0%, #3C3C3C 100%);
  }
  .making-wave-making {
    padding-top: 20% !important;
    margin-left: 10%;
  }
  .ut-enim-ad-minim-ven1011 {
    margin-left: 10%;
    width: auto;
    height: auto!important;
  }
  .background_journey {
    background: url("../Images/Journey1.png") no-repeat center;
    margin-top: -20%;
  }
  .jmar {
    margin-left: -3% !important;
  }
  .jmar1 {
    margin-left: -3% !important;
  }
  .appv {
    margin-top: 30% !important;
  }
  .mte {
    margin-top: 10%;
  }
  .smart-hydration-for-Product {
    margin-top: 30% !important;
  }
  ul.inline li {
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 30px;
    margin-top: 10%
  }
  .just-because-youve-f {
    color: #374667;
    font-family: Poppins;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 45px;
    width: 100%;
  }
  .ut-enim-ad-minim-ven-f {
    color: #374667;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 32px;
    width: 100%;
  }
 
  .testnav {
    margin-right: 0% !important;
  }
  .water-is-lifes-matt {
    color: #3C3C3C;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 30px;
    width: 80%;
    margin-left: 10%;
    margin-top: -50%
  }
  .albert-szent-gyorg {
    opacity: 0.54;
    color: #3C3C3C;
    font-family: Poppins;
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    vertical-align: center;
    width: 60%;
    margin-left: 25%;
  }
  .quotetest {
    height: 80px;
    margin-top: 15%;
    margin-left: -10%;
  }
  .as-they-say {
    font-size: 40px;
  }
  .oval {
    height: 320px;
    width: 320px;
    background-color: white;
    box-shadow: 10px 40px 60px 0 rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    margin: 0 auto;
  }
  .ibox {
    margin-top: 3% !important;
    width: auto;
  }
  .ibtn {
    margin-top: 20%;
    background-color: 'red';
  }
  .ibox1 {
    top: 60%!important;
    left: 20%!important;
    outline: none;
    height: 30px !important;
    width: 300px !important;
  }
}

.approachm {
  margin-top: 10% !important;
}

.downa {
  margin-top: 20%;
}

.textimage {
  margin-top: 0% !important;
  justify-content: center !important;
}

.textimage1 {
  margin-top: 15% !important;
  margin-left: 0% !important;
  justify-content: center !important;
}

.errorcolor {
  color: red;
  /* position: absolute;
  top: 93%;
  left: 41%; */
  font-size: 14px;
  margin-top: -8%;
  font-weight: 500;
  margin-left: -2%;
}

.smart-hydration-for-Product {
  margin-top: 15%;
}


.mask {
  height: 800px;
  width: 100%;
  background: linear-gradient(180deg, #1F61BE 0%, #0B4392 100%);
}

.smart-hydration-for-Product {
  color: #1F61BE;
  font-family: Poppins;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -0.8px;
  line-height: 51px;
  text-align: center;
  padding-top: 20%;
}

.pclass {
  margin-top: 0%;
}

@media (max-width: 450px) {
  .errorcolor {
    color: red;
    /* position: absolute;
    top: 93%;
    left: 41%; */
    font-size: 14px;
    margin-top: -1%;
    font-weight: 500;
    margin-left: -10%;
  }
  .smart-hydration-for-Product {
    margin-top: 15%;
  }
  .mask {
    height: 800px;
    width: 100%;
    background: linear-gradient(180deg, #1F61BE 0%, #0B4392 100%);
  }
  .smart-hydration-for-Product {
    color: #1F61BE;
    font-family: Poppins;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -0.8px;
    line-height: 51px;
    text-align: center;
    padding-top: 20%;
  }
  .pclass {
    margin-top: 0%;
  }
  .navbar {
    padding: 10px 20px !important;
  }
  .in-depth-innovative {
    font-size: 21px;
  }

}

.circle {
  position: relative;
  display: block;
  margin: 2em 0;
  background-color: transparent;
  color: #222;
  text-align: center;
}

.circle:after {
  display: block;
  padding-bottom: 100%;
  width: 100%;
  height: 0;
  border-radius: 50%;
  background-color: #ddd;
  content: "";
}

.circle__inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.circle__wrapper {
  display: table;
  width: 100%;
  height: 100%;
}

.circle__content {
  display: table-cell;
  padding: 1em;
  vertical-align: middle;
}

@media (min-width: 480px) {
  .circle__content {
    font-size: 2em;
  }
  
}
@media (min-width: 1471) {
  .rectangle_graident {
    margin-top: -8%;
  }

  
}

@media (min-width: 768px) {
  .circle__content {
    font-size: 4em;
  }
}

.stay_connected_main_section {
  position: relative;
}

.get_in_touch_txt {
  width: 100%;
}

.mobiledown_2 {
  margin-top: 38px;
  
}

.mobiledown {
  margin-top: 38px;
}

.stay-connected-outer {
  position: absolute;
  z-index: 99999;
  left: 0;
  top: 28px;
}

/* responsiveness for mobile */

@media (max-width: 1366px) {
  .rectangle_graident {
    margin-top: 8%;
  }
  .arrowdiv {
    padding-top: 267px !important;
  }
  .section_1 {
    padding-top: 218px !important;
}

}
@media (max-width: 1024px) {

  .section_1 {
    padding-top: 62px !important;
}
.stay-connected {
  font-size: 60px !important;
}
.smart-hydration-for {
  font-size: 52px;
  line-height: 63px;
}
#contact_us {
  padding-top: 0 !important;
}
.arrowdiv {
  padding-top: 177px !important;
}
.rectangle_graident {
  margin-top: -1% !important;
}

}
@media (max-width: 768px) {
 
  .ut-enim-ad-minim-ven1011_1{
    width: auto !important;
  }
  .making-wave-making1 {
    padding-left: 50px;
}
.ut-enim-ad-minim-ven1011_1 {
  padding-right: 50px;
  padding-left: 50px;
}
#getintouchdiv {
  padding: 70px 154px 0 50px !important;
}
.for-a-project-as-a {
  padding-top: 30px;
}
.rectangle_graident {
  padding-top: 22% !important;
}
.arrowdiv {
  padding-top: 265px !important;
}
.quotetest {
  margin-left: 0!important;
}
.as-they-say {
  padding-left: 65px;
}
.section_1 {
  padding-top: 133px !important;
}
.navbar {
  padding: 25px 50px 10px 50px !important;
}
.making-wave-making1 {
  top: 29% !important ;
}
.ut-enim-ad-minim-ven1011_1 {
  top: 54% !important;
}

}
@media (max-width: 514px) {

  .testim {
    height: 715px !important;
}
  .making-wave-making1 {
    font-size: 35px !important;
    margin-top: -35% !important;
    line-height: 53px !important;
}
  .section_1 {
    padding-top: 63px !important;
}
.in-depth-innovative {
  padding-top: 16px !important;
}
.arrowdiv {
  padding-top: 180px !important;
}
.rectangle_graident {
  margin-top: -2% !important;
}
.as-they-say {
  padding-left: 0 !important;
}
.water-is-lifes-matt {
  text-align: left !important;
  margin-top: -42% !important;
}
.albert-szent-gyorg {
  text-align: left !important;
  margin-left: 19% !important;
}
.oapproach {
  padding-left: 26px !important;
}
.making-wave-making1 {
  font-size: 35px !important;
  line-height: 53px !important;
}
.ut-enim-ad-minim-ven1011_1 {
  margin-top: -67% !important;
}
#getintouchdiv {
  padding: 50px 50px 0 50px !important;
}
.stay-connected {
  font-size: 38px !important;
}
.for-a-project-as-a {
  padding-top: 0px !important;
  margin-bottom: 0;
}
.mobiledown {
  padding-top: 49px;
  margin-top: 0;
}
#Approach{
  margin-top: 10% !important;
}
.logoim{
  width: 70px;
}

}
@media (max-width: 414px) {
  .stay-connected {
    font-size: 31px !important;
  }
  .making-wave-making1 {
    font-size: 28px !important;
  }
  .section_1 {
    padding-top: 91px !important;
  }
  .smart-hydration-for {
    font-size: 27px;
      line-height: 43px;
  }
  .ut-enim-ad-minim-ven1011_1 {
    margin-top: -38% !important;
  }
  .making-wave-making1 {
    margin-top: -22% !important;
  }

}
@media (max-width: 375px) {
  .smart-hydration-for {
    font-size: 26px;
    line-height: 41px;
  }
  .section_1 {
    padding-top: 122px !important;
  }
  .arrowdiv {
    padding-top: 150px !important;
  }
  .in-depth-innovative {
    font-size: 18px !important;
  }
  .rectangle_graident {
    margin-top: 10% !important;
  }
  .get_in_touch_btn {
    font-size: 16px !important;
  }
  .logoim{
    width: 70px !important;
  }
  .making-wave-making1 {
    font-size: 25px !important;
    line-height: 43px !important;
  }
  .ut-enim-ad-minim-ven1011_1 {
    font-size: 16px;
  }
  .ut-enim-ad-minim-ven1011_1 {
    margin-top: -41% !important;
  }
  .making-wave-making1 {
    margin-top: -22% !important;
  }

}
@media (max-width: 320px) {
  .oval {
    width: 100% !important;
  }
  #Approach {
    margin-top: 18% !important;
  }
  .rectangle_graident {
    margin-top: -27% !important;
  }
  .smart-hydration-for {
    font-size: 21px !important;
    line-height: 37px !important;
  }
  .section_1 {
    padding-top: 69px !important;
  }
  .in-depth-innovative {
    font-size: 15px !important;
  }
  .navbar {
    padding: 25px 37px 10px 37px !important;
  }
  .get_in_touch_btn {
    font-size: 14px !important;
  }
  .logoim{
    width: 70px !important;
  }
  .as-they-say {
    font-size: 29px !important;
  }
  .water-is-lifes-matt {
    text-align: left !important;
    margin-top: -30% !important;
  }
  .water-is-lifes-matt {
    color: #3C3C3C;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
    width: 82%;
    margin-left: 7%;
  }
  .albert-szent-gyorg {
    width: 66% !important;
  }
  .albert-szent-gyorg {
    text-align: left !important;
    margin-left: 19% !important;
  }
  .stay-connected {
    font-size: 24px !important;
  }
  .ut-enim-ad-minim-ven1011_1 {
    font-size: 16px !important;
    line-height: 33px !important;
    top: 67% !important;
  }
  .ut-enim-ad-minim-ven1011_1 {
    margin-top: -90% !important;
  }
  .making-wave-making1 {
    margin-top: -34% !important;
  }

}
/* css by narinder */

.navbar{
  padding: 25px 153px 10px 153px;
}
.navbar-brand{
  cursor: pointer !important;
}
.get_in_touch_btn{
  color: #374667 !important;
  font-family: Poppins ;
  font-size: 19px ;
  font-weight: 600 ;
  cursor: pointer;
}
.get_in_touch_btn:hover{
  color: #1F61BE !important;
}
.section_1{
  padding-top: 80px;
}
.arrowdiv_img{
  cursor: pointer;
}
#getintouchdiv{
  padding: 100px 154px 0 140px;
  margin-top: -1px;
}
#contact_us{
  padding-top: 50px;
}
/* .mobiledown_2 {
  position: absolute;
  right: 21.5%;
  top: 37px;
} */

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.84;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  opacity: 0.84;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
}

::-ms-input-placeholder { /* Microsoft Edge */
  opacity: 0.84;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
}
.send_btn_{
  padding-top: 30px;
}
.ibtn-Product:hover{
  background-color: #1d4986;
}
.explore:hover{
  color: #1F61BE;
}